// dependencies
import {
  registerReactCustomDetailComponent,
  registerReactFormInputComponent
} from '@vendure/admin-ui/react';
// components
import GenerateGUIDButton from './components/generate-code-button';
import ManagePartnerBalance from './components/manage-partner-balance';
import OpenPartnerBO from './components/open-partner-bo';
import RemoveSellerProperties from './components/remove-seller-properties';
import {InterlocutorsListComponent} from './components/interlocutor-tab/interlocutors-list-component';

export default [
  registerReactCustomDetailComponent({
    locationId: 'seller-detail',
    component: ManagePartnerBalance
  }),
  registerReactCustomDetailComponent({
    locationId: 'seller-detail',
    component: OpenPartnerBO
  }),
  registerReactCustomDetailComponent({
    locationId: 'seller-detail',
    component: RemoveSellerProperties
  }),
  registerReactFormInputComponent('generate-guid-button', GenerateGUIDButton),
  registerReactFormInputComponent('interlocutors-component', InterlocutorsListComponent),
  
];
