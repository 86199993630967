// dependencies
import React, { useState } from 'react';
import { I18nService } from '@vendure/admin-ui/core';
import { useInjector, useDetailComponentData } from '@vendure/admin-ui/react';
// components
import { Card } from '@vendure/admin-ui/react';
import { MantineProvider, Tabs } from '@mantine/core';
import ManagePartnerCredits from './manage-partner-credits';
import ManagePartnerHighlights from './manage-partner-highlights';
// utils
import { mantineTheme } from '../utils/theme';

const ManagePartnerBalance = () => {
  const i18n = useInjector(I18nService);
  const [activeTab, setActiveTab] = useState<string | null>('credits');

  return (
    <MantineProvider theme={mantineTheme}>
      <Card>
        <Tabs
          value={activeTab}
          onChange={setActiveTab}
          classNames={{
            tab: 'mantine-tab',
            tabLabel: 'mantine-tab-label'
          }}>
          <Tabs.List>
            <Tabs.Tab value='credits'>{i18n.translate('manage-partner-credits.credits')}</Tabs.Tab>
            <Tabs.Tab value='highlights'>
              {i18n.translate('manage-partner-highlights.highlights')}
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value='credits'>
            <ManagePartnerCredits />
          </Tabs.Panel>
          <Tabs.Panel value='highlights'>
            <ManagePartnerHighlights />
          </Tabs.Panel>
        </Tabs>
      </Card>
    </MantineProvider>
  );
};

export default ManagePartnerBalance;
