// dependencies
import { addNavMenuItem } from '@vendure/admin-ui/core';

export default [
  addNavMenuItem(
    {
      id: 'credits-and-highlights',
      label: 'Gerir Produtos de Crédito',
      routerLink: ['/extensions/credits-and-highlights'],
      icon: 'wallet'
    },
    'catalog'
  )
];
