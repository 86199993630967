// dependencies
import { createTheme, rem } from '@mantine/core';

export const mantineTheme = createTheme({
  primaryColor: 'orange',
  colors: {
    orange: [
      '#FFEDD5', // lightest orange
      '#FED7AA', // very light orange
      '#FDAC3E', // light orange
      '#FB8C00', // medium orange
      '#F76C00', // bright orange
      '#F55F00', // vibrant orange
      '#F44C00', // strong orange
      '#F33A00', // darker orange
      '#F22900', // deep orange
      '#F11F00' // darkest orange
    ]
  },
  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)'
  },
  headings: {
    fontFamily: 'Roboto, sans-serif',
    sizes: {
      h1: { fontSize: rem(36) }
    }
  }
});
