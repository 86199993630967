// dependencies
import { addNavMenuItem, addNavMenuSection } from '@vendure/admin-ui/core';

export default [
  addNavMenuItem(
    {
      id: 'products',
      label: 'Imóveis',
      routerLink: ['/catalog/products']
    },
    'catalog'
  ),
  addNavMenuItem(
    {
      id: 'shipping-methods',
      label: 'Shipping Method',
      routerLink: [''],
      requiresPermission: '__disabled__'
    },
    'settings'
  ),
  addNavMenuItem(
    {
      id: 'stock-locations',
      label: 'Stock Locations',
      routerLink: [''],
      requiresPermission: '__disabled__'
    },
    'settings'
  ),
  addNavMenuItem(
    {
      id: 'payment-methods',
      label: 'Payment Methods',
      routerLink: [''],
      requiresPermission: '__disabled__'
    },
    'settings'
  ),
  addNavMenuItem({
    id: 'collections', 
    label: 'Collections',
    routerLink: ['/catalog', 'collections'],
    requiresPermission: '__disable__'
},
'catalog'),
  addNavMenuSection({
    id: 'marketing',
    label: 'Marketing',
    items: [],
    requiresPermission: '__disabled__'
  }),
  addNavMenuSection({
    id: 'marketing',
    label: 'Marketing',
    items: [],
    requiresPermission: '__disabled__'
  }),
  addNavMenuSection({
    id: 'customers',
    label: 'Customers',
    items: [],
    requiresPermission: '__disabled__'
  }),
  addNavMenuSection({
    id: 'sales',
    label: 'Sales',
    items: [],
    requiresPermission: '__disabled__'
  }),
  // addNavMenuSection({
  //   id: 'system',
  //   label: 'System',
  //   items: [],
  //   requiresPermission: '__disabled__'
  // })
];
