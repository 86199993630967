// dependencies
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { I18nService } from '@vendure/admin-ui/core';
import { useDetailComponentData, useInjector } from '@vendure/admin-ui/react';
// components
import { IconArrowRight, IconChevronDown, IconChevronUp, IconTrash } from '@tabler/icons-react';
import { Button, Flex, Grid, Select, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
// hooks
import { useAppConfig } from '../hooks/useAppConfig';
// utils
import { convertDateToDisplay, getToken } from '../utils/functions';

const ManagePartnerHighlights = () => {
  const { entity } = useDetailComponentData();
  const { config } = useAppConfig();
  const i18n = useInjector(I18nService);
  const [highlightsPackAvailable, setHighlightsPackAvailable] = useState<string | null>(null);
  const [highlightsPackSelection, setHighlightsPackSelection] = useState<string | null>(null);
  const [highlightsExpirationDate, setHighlightsExpirationDate] = useState<Date | null>(null);
  const [highlightsExpirationDateTime, setHighlightsExpirationDateTime] = useState<Date | null>(
    null
  );
  const [highlightsSelectOpened, setHighlightsSelectOpened] = useState<boolean>(false);
  const [highlightsPacksList, setHighlightsPacksList] = useState<
    { label: string; value: string }[]
  >([]);
  const [assignHighlightsLoading, setAssignHighlightsLoading] = useState<boolean>(false);
  const [assignHighlightsEnabled, setAssignHighlightsEnabled] = useState<boolean>(false);

  const getHighlightsPacks = async () => {
    if (!config) return; // Exit early if config is not available

    try {
      // Get vnd__authToken from localStorage
      const token = getToken();
      const {
        data: { result = [] }
      } = await axios.get(`${config.creditsHighlightsMs}/get-highlights-packs`, {
        headers: {
          Authorization: token
        }
      });

      const sortedResults = result.sort(
        (a: any, b: any) => a.highlightsPackValue - b.highlightsPackValue
      );

      // Handle empty or invalid result gracefully
      const translatedHighlights = sortedResults.map(
        (highlights: {
          highlightsPackValue: number;
          highlightsPackName: string;
          highlightsPackId: string;
        }) => ({
          value: highlights.highlightsPackValue.toString(),
          label: i18n.translate(`manage-partner-highlights.${highlights.highlightsPackName}`)
        })
      );

      setHighlightsPacksList(translatedHighlights);
    } catch (error) {
      console.error('Error fetching highlights packs:', error);
    }
  };

  // Get Partner Wallet
  const getPartnerWallet = async () => {
    if (!config || !entity) return; // Exit early if config is not available
    try {
      // Get vnd__authToken from localStorage
      const token = getToken();
      const {
        data: { result = {} }
      } = await axios.get(`${config.creditsHighlightsMs}/get-partner-wallet/${entity.id}`, {
        headers: {
          Authorization: token
        }
      });
      if (result.highlightsPack) setHighlightsPackAvailable(result.highlightsPack);
      if (result.expirationDateHighlights)
        setHighlightsExpirationDate(new Date(result.expirationDateHighlights));
    } catch (error) {
      console.log('Error fetching partner wallet:', error);
    }
  };

  const handleAssignHighlightsPack = async () => {
    if (!config || !entity) return; // Exit early if config or entity is not available

    try {
      // Get vnd__authToken from localStorage
      const token = getToken();

      setAssignHighlightsLoading(true);

      const payload = {
        partnerId: entity.id,
        transactionOwner: 'admin',
        highlightsTransaction: {
          highlightsPack: highlightsPackSelection,
          ...(highlightsExpirationDateTime && {
            expirationDateHighlights: highlightsExpirationDateTime.toISOString()
          })
        }
      };

      const {
        data: {
          result: { walletResult = [] }
        }
      } = await axios.post(`${config.creditsHighlightsMs}/update-partner-wallet`, payload, {
        headers: {
          Authorization: token
        }
      });

      if (walletResult?.[0]) {
        const { highlightsPack, expirationDateHighlights } = walletResult[0];

        if (highlightsPack) {
          setHighlightsPackAvailable(highlightsPack);
        }
        if (expirationDateHighlights) {
          setHighlightsExpirationDate(new Date(expirationDateHighlights));
        }
      }
    } catch (error) {
      console.log('Error assigning highlights pack:', error);
    } finally {
      setAssignHighlightsLoading(false);
    }
  };

  // Handle clean fields
  const handleCleanFields = () => {
    setHighlightsPackSelection(null);
    setHighlightsExpirationDateTime(null);
  };

  useEffect(() => {
    getHighlightsPacks(); // Run once when config is available
  }, [config]);

  useEffect(() => {
    getPartnerWallet(); // Run once when config and entity is available
  }, [config, entity]);

  useEffect(() => {
    if (highlightsPackSelection && !highlightsExpirationDateTime) {
      // Set Default Expiration Date for a timestamp from today + 1 year
      const defaultExpirationDate = new Date();
      defaultExpirationDate.setFullYear(defaultExpirationDate.getFullYear() + 1);
      setHighlightsExpirationDateTime(defaultExpirationDate);
    }
  }, [highlightsPackSelection]);

  useEffect(() => {
    let isFormValid = true;

    // Early exit if common fields are invalid
    if (!highlightsPackSelection || !highlightsExpirationDateTime) {
      isFormValid = false;
    }

    // Disable the button if the expiration date is in the future
    if (highlightsExpirationDate && new Date(highlightsExpirationDate).getTime() > Date.now()) {
      isFormValid = false;
    }

    setAssignHighlightsEnabled(isFormValid);
  }, [highlightsPackSelection, highlightsExpirationDateTime, highlightsExpirationDate]);

  return (
    <Grid styles={{ root: { marginBottom: '10px' } }}>
      {/* Highlights Available */}
      <Grid.Col span={{ sm: 12, md: 12, lg: 12 }} styles={{ col: { padding: '10px' } }}>
        <Flex direction='row' align='center' justify='flex-start'>
          <Text mt={20} mr={22}>{`${i18n.translate(
            'manage-partner-highlights.highlights-available'
          )} ${highlightsPackAvailable ? highlightsPackAvailable : '--'}`}</Text>
          {highlightsExpirationDate && (
            <Text mt={20}>{`${i18n.translate(
              'manage-partner-highlights.expire-in'
            )} ${convertDateToDisplay(highlightsExpirationDate)}`}</Text>
          )}
        </Flex>
      </Grid.Col>
      {/* Highlights Selection */}
      <Grid.Col span={{ sm: 12, md: 6, lg: 6 }} styles={{ col: { padding: '10px' } }}>
        <Select
          classNames={{
            input: 'mantine-select',
            dropdown: 'mantine-select-dropdown',
            option: 'mantine-select-option'
          }}
          value={highlightsPackSelection}
          onChange={value => setHighlightsPackSelection(value)}
          rightSection={
            highlightsSelectOpened ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />
          }
          rightSectionWidth={30}
          onDropdownOpen={() => setHighlightsSelectOpened(true)}
          onDropdownClose={() => setHighlightsSelectOpened(false)}
          data={highlightsPacksList}
          placeholder={i18n.translate('manage-partner-highlights.highlights-placeholder')}
        />
      </Grid.Col>
      {highlightsPackSelection && highlightsPackSelection !== '--' && (
        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }} styles={{ col: { padding: '10px' } }}>
          <DateTimePicker
            classNames={{
              input: 'mantine-input'
            }}
            minDate={new Date()}
            valueFormat='YYYY MMM DD HH:mm:ss'
            placeholder={i18n.translate('manage-partner-highlights.highlights-expiration-date')}
            value={highlightsExpirationDateTime}
            onChange={setHighlightsExpirationDateTime}
          />
        </Grid.Col>
      )}
      <Grid.Col span={{ sm: 12, md: 12, lg: 12 }} styles={{ col: { padding: '10px' } }}>
        <Flex direction='row' align='center' justify='flex-start' gap={12}>
          <Button
            onClick={() => handleAssignHighlightsPack()}
            loading={assignHighlightsLoading}
            disabled={!assignHighlightsEnabled}
            styles={{
              root: {
                width: '170px',
                height: '40px',
                padding: '12px',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '20px',
                borderRadius: '12px'
              }
            }}
            rightSection={<IconArrowRight size={14} />}
            variant='filled'>
            {i18n.translate('manage-partner-highlights.assign-highlights')}
          </Button>
          <Button
            onClick={() => handleCleanFields()}
            disabled={!highlightsPackSelection && !highlightsExpirationDateTime}
            styles={{
              root: {
                width: '150px',
                height: '40px',
                padding: '12px',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '20px',
                borderRadius: '12px'
              }
            }}
            rightSection={<IconTrash size={14} />}
            variant='default'>
            {i18n.translate('manage-partner-highlights.clean-fields')}
          </Button>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default ManagePartnerHighlights;
