export const extensionRoutes = [  {
    path: 'extensions/software-houses',
    loadChildren: () => import('./extensions/software-houses/routes'),
  },
  {
    path: 'extensions/statistics',
    loadChildren: () => import('./extensions/statistics/routes'),
  },
  {
    path: 'extensions/credits-and-highlights',
    loadChildren: () => import('./extensions/credits-and-highlights/routes'),
  }];
