// dependencies
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { I18nService } from '@vendure/admin-ui/core';
import { useDetailComponentData, useInjector } from '@vendure/admin-ui/react';
import { Button, MantineProvider } from '@mantine/core';
// hooks
import { useAppConfig } from '../hooks/useAppConfig';
// utils
import { mantineTheme } from '../utils/theme';

const OpenPartnerBO = () => {
  const { config } = useAppConfig();
  const { entity } = useDetailComponentData();
  const i18n = useInjector(I18nService);
  const openPartnerBORef = useRef<HTMLButtonElement>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateButtonPosition = useCallback(() => {
    const saveChangesButton = Array.from(document.querySelectorAll('button')).find(
      button =>
        button.textContent === ' Save changes ' || button.textContent === ' Guardar alterações '
    );

    if (!saveChangesButton) return;

    const offsetTop = saveChangesButton.offsetTop;
    const offsetLeft = saveChangesButton.offsetLeft;
    const openPartnerBO = openPartnerBORef.current;

    if (openPartnerBO) {
      openPartnerBO.style.position = 'absolute';
      const leftPosition = offsetLeft - openPartnerBO.offsetWidth - 20;
      const topPosition = offsetTop;
      openPartnerBO.style.left = `${leftPosition}px`;
      openPartnerBO.style.top = `${topPosition}px`;
    }
  }, [windowWidth]);

  useEffect(() => {
    updateButtonPosition();

    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateButtonPosition]);

  return (
    <MantineProvider theme={mantineTheme}>
      <Button
        onClick={() => {
          if (!config || !entity) return;
          window.open(`${config.frontendURL}/backoffice/admin/${entity.id}/carteira-agencia/comprar`, '_blank');
        }}
        ref={openPartnerBORef}
        styles={{
          root: {
            backgroundColor: '#f06937',
            fontSize: '14px',
            padding: '8px 12px',
            height: '40px'
          }
        }}>
        {i18n.translate('seller-detail.open-partner-bo')}
      </Button>
    </MantineProvider>
  );
};

export default OpenPartnerBO;
