import { addNavMenuSection } from '@vendure/admin-ui/core';

export default [
  addNavMenuSection(
    {
      id: 'metrics-section',
      label: 'Métricas',
      items: [
        {
          id: 'statistics-tab',
          label: 'Estatísticas',
          routerLink: ['/extensions/statistics'],
          icon: 'dashboard'
        }
      ]
    },
    'settings'
  )
];
