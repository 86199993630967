// dependencies
import React from 'react';
import { useFormControl, ReactFormInputOptions } from '@vendure/admin-ui/react';
import { v4 as uuidv4 } from 'uuid';

const GenerateGUIDButton = ({ readonly, config }: ReactFormInputOptions) => {
  const { value, setFormValue } = useFormControl();

  const generateGuid = () => {
    const newGuid = uuidv4();
    setFormValue(newGuid);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <input
        type='text'
        value={value}
        style={{ flex: '1' }}
        onChange={(event: any) => setFormValue(event.target.value)}
      />

      {/* Botão para gerar novo GUID */}
      <button type='button' className='button secondary' onClick={generateGuid}>
        Gerar novo GUID
      </button>
    </div>
  );
};

export default GenerateGUIDButton;
