// dependencies
import { useEffect, useState } from 'react';

type AppConfig = {
  creditsHighlightsMs: string;
  frontendURL: string;
};

export const useAppConfig = (): { config?: AppConfig } => {
  const [config, setConfig] = useState<AppConfig>();

  useEffect(() => {
    const getConfig = async (): Promise<AppConfig> => {
      /* LOCAL ENV - `${window.location.origin}/admin/assets/environment.json` */
      const response = await fetch(`${window.location.origin}/assets/environment.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Server returned an error: ${response.status} ${response.statusText}`);
      }

      const contentType = response.headers.get('Content-Type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error(`Server returned an invalid response: ${contentType}`);
      }

      const data = await response.json();

      return data;
    };

    getConfig().then(setConfig);
  }, []);

  return { config };
};
