import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { CdsIcon, useInjector, useLazyQuery, useMutation } from '@vendure/admin-ui/react';
import { trashIcon } from '@cds/core/icon';
import { NotificationService, I18nService } from '@vendure/admin-ui/core';
import { Checkbox, Table, MantineProvider } from '@mantine/core';
import { Modal } from 'ts-react-components-lib';
import { ID } from '@vendure/core';
import { mantineTheme } from '../../utils/theme';

interface Interlocutor {
  id: ID;
  name: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
}

interface GetInterlocutorsData {
  interlocutors: Interlocutor[];
}

const GET_INTERLOCUTORS = gql`
  query GetInterlocutors($sellerId: ID!) {
    interlocutors(sellerId: $sellerId) {
      id
      name
      email
      phone
      createdAt
      updatedAt
    }
  }
`;

const CREATE_INTERLOCUTOR = gql`
  mutation CreateInterlocutor($input: InterlocutorInput!) {
    createInterlocutor(input: $input) {
      id
      name
      email
      phone
      createdAt
      updatedAt
    }
  }
`;

const DELETE_INTERLOCUTORS = gql`
  mutation DeleteInterlocutors($ids: [ID!]!) {
    deleteInterlocutors(ids: $ids) {
      __typename
      ... on Success {
        success
      }
      ... on RequiredFieldsError {
        errorCode
        message
        requiredFields
      }
      ... on InternalServerError {
        errorCode
        message
      }
    }
  }
`;
export function InterlocutorsListComponent() {
  const [selected, setSelected] = useState<Array<ID | string>>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [sellerId, setSellerId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newInterlocutor, setNewInterlocutor] = useState({ name: '', email: '', phone: '' });

  const i18n = useInjector(I18nService);
  const notificationService = useInjector(NotificationService);

  const [getInterlocutors, { data, loading, error }] =
    useLazyQuery<GetInterlocutorsData>(GET_INTERLOCUTORS);

  const [createInterlocutor] = useMutation(CREATE_INTERLOCUTOR);

  const [deleteInterlocutors] = useMutation(DELETE_INTERLOCUTORS);

  useEffect(() => {
    const parts = window.location.pathname.split('/');
    const id = parts[parts.length - 1];

    if (id && !isNaN(Number(id))) {
      setSellerId(id);
      getInterlocutors({ sellerId: id });
    }
  }, []);

  useEffect(() => {
    if (error) {
      notificationService.error('Erro ao carregar a lista de interlocutores.');
    }
  }, [error]);

  const handleSelectAllCheck = () => {
    setAllSelected(!allSelected);
    setSelected(allSelected ? [] : data?.interlocutors.map(item => item.id) || []);
  };

  const handleSelectChange = (id: ID) => {
    setSelected(selected.includes(id) ? selected.filter(item => item !== id) : [...selected, id]);
  };

  const handleCreateInterlocutor = async () => {
    if (!sellerId) {
      notificationService.error('Erro: ID do agência não encontrado.');
      return;
    }

    if (!newInterlocutor.name || !newInterlocutor.email || !newInterlocutor.phone) {
      notificationService.error(
        'Por favor, preencha todos os campos antes de criar o interlocutor.'
      );
      return;
    }

    try {
      const result: any = await createInterlocutor({
        input: {
          name: newInterlocutor.name,
          email: newInterlocutor.email,
          phone: newInterlocutor.phone,
          seller: sellerId as ID
        }
      });

      if (result?.createInterlocutor) {
        notificationService.success('Interlocutor criado com sucesso!');
        setIsModalOpen(false);
        setNewInterlocutor({ name: '', email: '', phone: '' });
        getInterlocutors({ sellerId: sellerId });
      }
    } catch (error) {
      notificationService.error('Ocorreu um erro ao tentar criar o interlocutor.');
      console.error(error);
    }
  };

  const handleDeleteInterlocutors = async (ids: Array<ID>) => {
    if (ids.length === 0) {
      notificationService.error('Nenhum interlocutor selecionado para eliminar.');
      return;
    }

    try {
      await deleteInterlocutors({ ids });
      notificationService.success('Interlocutores eliminados com sucesso!');
      setSelected([]);
      getInterlocutors({ sellerId: sellerId });
    } catch (error) {
      notificationService.error('Erro ao eliminar interlocutores.');
      console.error(error);
    }
  };

  const handleDeleteIndividual = (id: ID) => {
    handleDeleteInterlocutors([id]);
  };

  return (
    <div style={{ padding: '12px', maxWidth: '100%', fontSize: '14px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '10px'
        }}>
        <button type='button' className='button primary' onClick={() => setIsModalOpen(true)}>
          + Criar Novo Interlocutor
        </button>
        <button
          type='button'
          className='button secondary'
          onClick={() => handleDeleteInterlocutors(selected)}
          disabled={selected.length === 0}
          style={{ marginLeft: '12px' }}>
          <CdsIcon icon={trashIcon} />
          {i18n.translate('interlocutors.delete-all')}
        </button>
      </div>

      <MantineProvider theme={mantineTheme}>
        <Table style={{ fontSize: '12px' }}>
          <Table.Thead>
            <Table.Tr style={{ height: '32px' }}>
              <Table.Th style={{ padding: '4px' }}>
                <Checkbox checked={allSelected} onChange={handleSelectAllCheck} size='sm' />
              </Table.Th>
              <Table.Th style={{ fontSize: '14px', padding: '4px' }}>
                {i18n.translate('interlocutors.name')}
              </Table.Th>
              <Table.Th style={{ fontSize: '14px', padding: '4px' }}>
                {i18n.translate('interlocutors.email')}
              </Table.Th>
              <Table.Th style={{ fontSize: '14px', padding: '4px' }}>
                {i18n.translate('interlocutors.phone')}
              </Table.Th>
              <Table.Th style={{ fontSize: '14px', padding: '4px' }}>
                {i18n.translate('interlocutors.created-at')}
              </Table.Th>
              <Table.Th style={{ fontSize: '14px', padding: '4px' }}>
                {i18n.translate('interlocutors.updated-at')}
              </Table.Th>
              <Table.Th style={{ fontSize: '14px', padding: '4px' }}></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td colSpan={7} align='center' style={{ fontSize: '12px', padding: '8px' }}>
                  Loading...
                </Table.Td>
              </Table.Tr>
            ) : data?.interlocutors.length === 0 ? (
              <Table.Tr>
                <Table.Td
                  colSpan={7}
                  align='center'
                  style={{ fontSize: '12px', padding: '8px', color: '#666' }}>
                  {i18n.translate('interlocutors.no-interlocutors')}
                </Table.Td>
              </Table.Tr>
            ) : (
              data?.interlocutors.map(interlocutor => (
                <Table.Tr key={interlocutor.id}>
                  <Table.Td style={{ padding: '4px' }}>
                    <Checkbox
                      checked={selected.includes(interlocutor.id)}
                      onChange={() => handleSelectChange(interlocutor.id)}
                      size='sm'
                    />
                  </Table.Td>
                  <Table.Td style={{ fontSize: '12px', padding: '4px' }}>
                    {interlocutor.name}
                  </Table.Td>
                  <Table.Td style={{ fontSize: '12px', padding: '4px' }}>
                    {interlocutor.email}
                  </Table.Td>
                  <Table.Td style={{ fontSize: '12px', padding: '4px' }}>
                    {interlocutor.phone}
                  </Table.Td>
                  <Table.Td style={{ fontSize: '12px', padding: '4px' }}>
                    {new Date(interlocutor.createdAt).toLocaleDateString()}
                  </Table.Td>
                  <Table.Td style={{ fontSize: '12px', padding: '4px' }}>
                    {new Date(interlocutor.updatedAt).toLocaleDateString()}
                  </Table.Td>
                  <Table.Td style={{ fontSize: '12px', padding: '4px' }}>
                    <button
                      className='button-small'
                      onClick={() => handleDeleteIndividual(interlocutor.id)}>
                      <CdsIcon icon={trashIcon} />
                      Eliminar
                    </button>
                  </Table.Td>
                </Table.Tr>
              ))
            )}
          </Table.Tbody>
        </Table>
      </MantineProvider>

      {/* Modal para criar interlocutor */}
      <Modal Open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <div>
          <div style={{ width: '100%' }}>
            <h6 style={{ marginBottom: '16px' }}>
              {' '}
              {i18n.translate('interlocutors.create-interlocutor')}
            </h6>
            <input
              type='text'
              placeholder='Nome'
              value={newInterlocutor.name}
              onChange={e => setNewInterlocutor({ ...newInterlocutor, name: e.target.value })}
              style={{
                width: '100%',
                marginBottom: '8px',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc'
              }}
            />
            <input
              type='email'
              placeholder='Email'
              value={newInterlocutor.email}
              onChange={e => setNewInterlocutor({ ...newInterlocutor, email: e.target.value })}
              style={{
                width: '100%',
                marginBottom: '8px',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc'
              }}
            />
            <input
              type='tel'
              placeholder='Telefone'
              value={newInterlocutor.phone}
              onChange={e => setNewInterlocutor({ ...newInterlocutor, phone: e.target.value })}
              style={{
                width: '100%',
                marginBottom: '16px',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc'
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                onClick={() => setIsModalOpen(false)}
                className='button secondary'
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  cursor: 'pointer'
                }}>
                {i18n.translate('interlocutors.cancel')}
              </button>
              <button
                onClick={handleCreateInterlocutor}
                className='button primary'
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  cursor: 'pointer'
                }}>
                {i18n.translate('interlocutors.confirm')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
