// dependencies
import React, { useEffect, useState } from 'react';
import { I18nService } from '@vendure/admin-ui/core';
import { useDetailComponentData, useInjector } from '@vendure/admin-ui/react';
import axios from 'axios';
// components
import { IconArrowRight, IconChevronDown, IconChevronUp, IconTrash } from '@tabler/icons-react';
import { Button, Flex, Grid, Input, Select, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
// hooks
import { useAppConfig } from '../hooks/useAppConfig';
// utils
import { convertDateToDisplay, getToken } from '../utils/functions';

const ManagePartnerCredits = () => {
  const { entity } = useDetailComponentData();
  // Regular expression to validate the input
  const validCreditVariationRegex = /^[+-]?\d*$/;
  const digitsAfterOperatorRegex = /^[+-]?\d+$/;
  const { config } = useAppConfig();
  const i18n = useInjector(I18nService);
  const [creditsAvailable, setCreditsAvailable] = useState<string | null>(null);
  const [creditsSelection, setCreditsSelection] = useState<string | null>(null);
  const [creditsVariation, setCreditsVariation] = useState<string>('');
  const [creditsExpirationDate, setCreditsExpirationDate] = useState<Date | null>(null);
  const [creditsExpirationDateTime, setCreditsExpirationDateTime] = useState<Date | null>(null);
  const [creditsDescription, setCreditsDescription] = useState<string>('');
  const [creditPacksList, setCreditPacksList] = useState<{ label: string; value: string }[]>([]);
  const [creditsSelectOpened, setCreditsSelectOpened] = useState<boolean>(false);
  const [assignCreditsLoading, setAssignCreditsLoading] = useState<boolean>(false);
  const [assignCreditsEnabled, setAssignCreditsEnabled] = useState<boolean>(false);

  const getCreditsPacks = async () => {
    if (!config) return; // Exit early if config is not available

    try {
      // Get vnd__authToken from localStorage
      const token = getToken();
      const {
        data: { result = [] }
      } = await axios.get(`${config.creditsHighlightsMs}/get-credit-packs`, {
        headers: {
          Authorization: token
        }
      });

      const sortedResults = result.sort((a: any, b: any) => a.creditPackValue - b.creditPackValue);

      // Handle empty or invalid result gracefully
      const mappedCredits = sortedResults.map(
        (credit: { creditPackValue: number; creditPackName: string; creditPackId: string }) => ({
          value: credit.creditPackValue.toString(),
          label: i18n.translate(`manage-partner-credits.${credit.creditPackName}`)
        })
      );

      // Default item and the corrective transaction option
      const translatedCredits = [
        ...mappedCredits,
        {
          label: i18n.translate('manage-partner-credits.corrective-transaction'),
          value: 'corrective-transaction'
        }
      ];

      setCreditPacksList(translatedCredits);
    } catch (error) {
      console.error('Error fetching credit packs:', error);
    }
  };

  // Get Partner Wallet
  const getPartnerWallet = async () => {
    if (!config || !entity) return; // Exit early if config is not available
    try {
      // Get vnd__authToken from localStorage
      const token = getToken();
      const {
        data: { result = {} }
      } = await axios.get(`${config.creditsHighlightsMs}/get-partner-wallet/${entity.id}`, {
        headers: {
          Authorization: token
        }
      });
      setCreditsAvailable(result.creditsAvailable);
      setCreditsExpirationDate(
        result.expirationDateCredits ? new Date(result.expirationDateCredits) : null
      );
    } catch (error) {
      console.log('Error fetching partner wallet:', error);
    }
  };

  const handleAssignCredits = async () => {
    if (!config || !entity) return; // Exit early if config or entity is not available

    try {
      // Get vnd__authToken from localStorage
      const token = getToken();

      setAssignCreditsLoading(true);

      const payload = {
        partnerId: entity.id,
        transactionOwner: 'admin',
        creditsTransaction: {
          creditsAmount:
            creditsSelection === 'corrective-transaction'
              ? creditsVariation
              : creditsSelection || creditsVariation,
          ...(creditsExpirationDateTime && {
            expirationDateCredits: creditsExpirationDateTime.toISOString()
          }),
          ...(creditsDescription && { description: creditsDescription })
        }
      };

      const {
        data: {
          result: { walletResult = [] }
        }
      } = await axios.post(`${config.creditsHighlightsMs}/update-partner-wallet`, payload, {
        headers: {
          Authorization: token
        }
      });

      if (walletResult?.[0]) {
        const { creditsAvailable, expirationDateCredits } = walletResult[0];

        if (
          creditsAvailable !== undefined &&
          creditsAvailable !== null &&
          typeof creditsAvailable !== undefined
        ) {
          setCreditsAvailable(creditsAvailable);
        }

        setCreditsAvailable(creditsAvailable);
        setCreditsExpirationDate(expirationDateCredits ? new Date(expirationDateCredits) : null);
      }
      // Reset fields after successful assign
      handleCleanFields();
    } catch (error) {
      console.log('Error assigning credits:', error);
    } finally {
      setAssignCreditsLoading(false);
    }
  };

  // Handle onChange of Select
  const handleCreditsSelectionChange = (value: string | null) => setCreditsSelection(value);

  // Handle the change of credits variation input
  const handleCreditsVariationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    // Check if the value matches the valid format
    if (validCreditVariationRegex.test(value)) {
      setCreditsVariation(value);
    } else {
      // If not valid, do nothing or reset the field to the last valid value
      // setCreditsVariation(creditsVariation); // Uncomment to prevent changing to invalid input
    }
  };

  // Handle clean fields
  const handleCleanFields = () => {
    setCreditsSelection(null);
    setCreditsVariation('');
    setCreditsExpirationDateTime(null);
    setCreditsDescription('');
  };

  useEffect(() => {
    getCreditsPacks(); // Run once when config is available
  }, [config]);

  useEffect(() => {
    getPartnerWallet(); // Run once when config and entity is available
  }, [config, entity]);

  useEffect(() => {
    if (creditsSelection && creditsSelection !== 'corrective-transaction') {
      setCreditsVariation('');
      setCreditsDescription('');
    }
    if (creditsSelection && !creditsExpirationDateTime) {
      // Set Default Expiration Date for a timestamp from today + 30 days
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      setCreditsExpirationDateTime(expirationDate);
    }
  }, [creditsSelection]);

  useEffect(() => {
    let isFormValid = true;

    // Early exit if common fields are invalid
    if (!creditsSelection || !creditsExpirationDateTime) {
      isFormValid = false;
    }

    // Validation for corrective-transaction case
    if (creditsSelection === 'corrective-transaction') {
      if (!creditsVariation || !digitsAfterOperatorRegex.test(creditsVariation)) {
        isFormValid = false;
      }
      if (!creditsDescription?.trim()) {
        // Simplified check for empty or whitespace-only string
        isFormValid = false;
      }
    }

    setAssignCreditsEnabled(isFormValid);
  }, [creditsSelection, creditsVariation, creditsExpirationDateTime, creditsDescription]);

  // Conditional component for "Corrective Transaction"
  const renderCorrectiveTransactionFields = () => (
    <>
      <Grid.Col span={{ sm: 12, md: 6, lg: 6 }} styles={{ col: { padding: '10px' } }}>
        <DateTimePicker
          classNames={{
            input: 'mantine-input'
          }}
          minDate={new Date()}
          valueFormat='YYYY MMM DD HH:mm:ss'
          placeholder={i18n.translate('manage-partner-credits.credits-expiration-date')}
          value={creditsExpirationDateTime}
          onChange={setCreditsExpirationDateTime}
        />
      </Grid.Col>
      <Grid.Col span={{ sm: 12, md: 6, lg: 6 }} styles={{ col: { padding: '10px' } }}>
        <Input
          value={creditsDescription}
          onChange={event => setCreditsDescription(event.currentTarget.value)}
          placeholder={i18n.translate('manage-partner-credits.credits-description')}
          classNames={{
            input: 'mantine-input'
          }}
        />
      </Grid.Col>
    </>
  );

  // Main JSX Render
  return (
    <Grid styles={{ root: { marginBottom: '10px' } }}>
      {/* Credits Available */}
      <Grid.Col span={{ sm: 12, md: 12, lg: 12 }} styles={{ col: { padding: '10px' } }}>
        <Flex direction='row' align='center' justify='flex-start'>
          <Text mt={20} mr={22}>{`${i18n.translate('manage-partner-credits.credits-available')} ${
            creditsAvailable ? creditsAvailable : '--'
          }`}</Text>
          {creditsExpirationDate && (
            <Text mt={20}>{`${i18n.translate(
              'manage-partner-credits.expire-in'
            )} ${convertDateToDisplay(creditsExpirationDate)}`}</Text>
          )}
        </Flex>
      </Grid.Col>
      {/* Credits Selection */}
      <Grid.Col
        span={{ sm: 12, md: 6, lg: 6 }}
        styles={{
          col: {
            padding: '10px'
          }
        }}>
        <Select
          classNames={{
            input: 'mantine-select',
            dropdown: 'mantine-select-dropdown',
            option: 'mantine-select-option'
          }}
          value={creditsSelection}
          onChange={handleCreditsSelectionChange}
          rightSection={
            creditsSelectOpened ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />
          }
          rightSectionWidth={30}
          onDropdownOpen={() => setCreditsSelectOpened(true)}
          onDropdownClose={() => setCreditsSelectOpened(false)}
          data={creditPacksList}
          placeholder={i18n.translate('manage-partner-credits.credits-placeholder')}
        />
      </Grid.Col>
      {/* Render Expiration Date/Variation Based on Credits */}
      {creditsSelection && (
        <Grid.Col
          span={{ sm: 12, md: 6, lg: 6 }}
          styles={{
            col: {
              padding: '10px'
            }
          }}>
          {creditsSelection === 'corrective-transaction' ? (
            <Input
              classNames={{
                input: 'mantine-input'
              }}
              value={creditsVariation}
              onChange={event => handleCreditsVariationChange(event)}
              placeholder={i18n.translate('manage-partner-credits.credits-variation')}
            />
          ) : (
            <DateTimePicker
              classNames={{
                input: 'mantine-input'
              }}
              minDate={new Date()}
              valueFormat='YYYY MMM DD HH:mm:ss'
              placeholder={i18n.translate('manage-partner-credits.credits-expiration-date')}
              value={creditsExpirationDateTime}
              onChange={setCreditsExpirationDateTime}
            />
          )}
        </Grid.Col>
      )}
      {/* Conditionally Render Corrective Transaction Fields */}
      {creditsSelection === 'corrective-transaction' && renderCorrectiveTransactionFields()}
      <Grid.Col span={{ sm: 12, md: 12, lg: 12 }} styles={{ col: { padding: '10px' } }}>
        <Flex direction='row' align='center' justify='flex-start' gap={12}>
          <Button
            loading={assignCreditsLoading}
            disabled={!assignCreditsEnabled}
            onClick={() => handleAssignCredits()}
            styles={{
              root: {
                width: '160px',
                height: '40px',
                padding: '12px',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '20px',
                borderRadius: '12px'
              }
            }}
            rightSection={<IconArrowRight size={14} />}
            variant='filled'>
            {i18n.translate('manage-partner-credits.assign-credits')}
          </Button>
          <Button
            disabled={
              !(
                creditsSelection ||
                creditsExpirationDateTime ||
                creditsVariation ||
                creditsDescription
              )
            }
            onClick={() => handleCleanFields()}
            styles={{
              root: {
                width: '150px',
                height: '40px',
                padding: '12px',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '20px',
                borderRadius: '12px'
              }
            }}
            rightSection={<IconTrash size={14} />}
            variant='default'>
            {i18n.translate('manage-partner-credits.clean-fields')}
          </Button>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default ManagePartnerCredits;
