import React, { useEffect, useRef, useCallback, useState } from 'react';
import { I18nService, NotificationService } from '@vendure/admin-ui/core';
import {
  useDetailComponentData,
  CdsIcon,
  useInjector,
  useLazyQuery,
  useMutation
} from '@vendure/admin-ui/react';
import { Button, MantineProvider, Modal, Text, Group, Stack } from '@mantine/core';
import { trashIcon } from '@cds/core/icon';
import gql from 'graphql-tag';
// hooks
import { useAppConfig } from '../hooks/useAppConfig';
// utils
import { mantineTheme } from '../utils/theme';

// Query to get the number of properties (replace this with actual query)
const GET_SELLER_PROPERTIES = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      items {
        productId
      }
      totalItems
    }
  }
`;

const DELETE_PRODUCTS = gql`
  mutation DeleteProducts($ids: [ID!]!) {
    deleteProducts(ids: $ids) {
      result
      message
    }
  }
`;

const RUN_PENDING_SEARCH_INDEX_UPDATES_MUTATION = gql`
  mutation runPendingSearchIndexUpdates {
    runPendingSearchIndexUpdates {
      __typename
      success
    }
  }
`;

const RemoveSellerProperties = () => {
  const { config } = useAppConfig();
  const { entity } = useDetailComponentData();
  const i18n = useInjector(I18nService);
  const notificationService = useInjector(NotificationService);
  const openPartnerBORef = useRef<HTMLButtonElement>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalOpen, setModalOpen] = useState(false);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [propertiesIDs, setPropertiesIDs] = useState([]);

  const [getSellerProperties] = useLazyQuery(GET_SELLER_PROPERTIES);

  const [deleteProductsMutation] = useMutation(DELETE_PRODUCTS);
  const [runPendingSearchIndexUpdates] = useMutation(RUN_PENDING_SEARCH_INDEX_UPDATES_MUTATION);

  const updateButtonPosition = useCallback(() => {
    const saveChangesButton = Array.from(document.querySelectorAll('button')).find(
      button =>
        button.textContent === ' Save changes ' || button.textContent === ' Guardar alterações '
    );

    if (!saveChangesButton) return;

    const offsetTop = saveChangesButton.offsetTop;
    const offsetLeft = saveChangesButton.offsetLeft;
    const removeSellerProperties = openPartnerBORef.current;

    if (removeSellerProperties) {
      removeSellerProperties.style.position = 'absolute';
      const leftPosition = offsetLeft - removeSellerProperties.offsetWidth - 230;
      const topPosition = offsetTop;
      removeSellerProperties.style.left = `${leftPosition}px`;
      removeSellerProperties.style.top = `${topPosition}px`;
    }
  }, [windowWidth]);

  // Fetch properties count when modal is opened
  const fetchPropertiesCount = async () => {
    if (!entity) return;
    try {
      const result: any = await getSellerProperties({
        input: { sellerId: entity.id }
      });
      setPropertiesCount(result.search.totalItems);
      setPropertiesIDs(result.search.items.map((item: any) => item.productId));
    } catch (error) {
      console.error('Failed to fetch properties', error);
    }
  };

  const handleRemoveProducts = async () => {
    if (!propertiesIDs.length) return;
    try {
      // Call deleteProducts mutation with all product IDs
      const result: any = await deleteProductsMutation({ ids: propertiesIDs });
      if (result.deleteProducts.every((resp: any) => resp.result === 'DELETED')) {
        notificationService.success(i18n.translate('seller-detail.products-removed-success'));
      } else {
        notificationService.warning(
          i18n.translate('seller-detail.some-products-could-not-be-removed')
        );
      }
      setModalOpen(false);
    } catch (error) {
      notificationService.error(i18n.translate('seller-detail.failed-to-remove-products'));
    }
  };

  useEffect(() => {
    updateButtonPosition();

    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateButtonPosition]);

  return (
    <MantineProvider theme={mantineTheme}>
      <Button
        onClick={() => {
          if (!config || !entity) return;
          fetchPropertiesCount();
          setModalOpen(true);
        }}
        ref={openPartnerBORef}
        styles={{
          root: {
            backgroundColor: '#F2F2F2',
            color: '#8C8C8C',
            fontSize: '14px',
            padding: '8px 12px',
            height: '40px',
            display: 'flex'
          }
        }}
        leftSection={<CdsIcon icon={trashIcon} />}>
        {i18n.translate('seller-detail.remove-seller-properties')}
      </Button>

      {/* Modal for confirmation */}
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title={i18n.translate('seller-detail.remove-seller-properties')}
        size='lg'
        centered
        styles={{
          title: {
            fontSize: '18px',
            fontWeight: 'bold'
          }
        }}>
        <Stack justify='flex'>
          {' '}
          <Text>
            {i18n.translate('seller-detail.remove-properties-count', {
              count: propertiesCount
            })}{' '}
            {i18n.translate('seller-detail.remove-properties-description')}
          </Text>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '15px'
            }}>
            <button
              onClick={() => setModalOpen(false)}
              className='button secondary'
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                cursor: 'pointer'
              }}>
              {i18n.translate('seller-detail.cancel')}
            </button>
            <button
              onClick={handleRemoveProducts}
              className='button primary'
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                cursor: 'pointer'
              }}>
              {i18n.translate('seller-detail.remove-properties-confirm')}
            </button>
          </div>
        </Stack>
      </Modal>
    </MantineProvider>
  );
};

export default RemoveSellerProperties;
