// dependencies
import moment from 'moment';

// Convert Date to YYYY MMM DD hh:mm A
export const convertDateToDisplay = (date: Date | null) => {
  if (!date) return null;
  return moment(date).format('YYYY MMM DD HH:mm:ss');
};

// Get auth token from local storage
export const getToken = () => {
  let token = localStorage.getItem('vnd__authToken') || '';

  // Clean token if it contains unnecessary characters
  token = token.replace(/^"|"$/g, '');

  // Add Bearer prefix if token is not empty
  if (token) {
    return `Bearer ${token}`;
  }

  return token;
};
